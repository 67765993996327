import "react-datetime/css/react-datetime.css";
import '../styles/style.scss'
import { Provider } from 'react-redux'
import store from '../store/store'
import { useState, useEffect } from 'react'
import Splash from "./Authentication/splash";
import Head from 'next/head';
import { AuthProvider } from "../components/AuthContext";
import AuthWrapper from "../components/AuthContext/AuthWrapper";
import PermissionWrapper from "../components/AuthContext/PermissionWrapper";
import { ChatProvider } from '../hooks/useConnectUser';
import { analytics } from '../config/firebase/firebase';
import { useRouter } from 'next/router';
import { checkSignUpCompleteAndRedirect } from '../modules/auth/onBoard';

function MyApp({ Component, pageProps }) {
    const [loadingPage, setLoading] = useState(true);
    const [oneSignalInit, setOneSignalInit] = useState(false);
    const router = useRouter();

    useEffect(() => {
        checkSignUpCompleteAndRedirect(router)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log('🚀 OneSignal - inside useEffect')
        window.OneSignal = window.OneSignal || []
        if (oneSignalInit || !window?.OneSignal) return

        if (!process.env.NEXT_PUBLIC_ONESIGNAL_APPID) {
            throw new Error('Set NEXT_PUBLIC_ONESIGNAL_APPID env variable')
        }

        if (!process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID) {
            throw new Error('Set NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID env variable')
        }

        if (typeof window?.OneSignal?.init === "function") {
            console.log('🚀 OneSignal - initializing one signal', )
            OneSignal.init({
                appId: process.env.NEXT_PUBLIC_ONESIGNAL_APPID,
                safari_web_id: process.env.NEXT_PUBLIC_ONESIGNAL_SAFARI_WEB_ID,
                notifyButton: {
                    enable: false,
                },
                serviceWorkerPath: './sw.js',
                allowLocalhostAsSecureOrigin: true,
            }).then(async () => {
                console.log('🚀 OneSignal - successfully initialized one signal', )
                await OneSignal.showSlidedownPrompt()
                setOneSignalInit(true)

                OneSignal.on('subscriptionChange', function (isSubscribed) {
                    console.log("The user's subscription state is now:", isSubscribed);
                  });
            }).catch(err => {
                console.error('❌ OneSignal - error initializing one signal', err)
            })
        }
    }, [oneSignalInit])

    useEffect(() => {
        analytics();

        setLoading(false);
        window.addEventListener('pointermove', handleScroll);
        window.addEventListener('touchmove', handleScroll);

        return () => {
            window.removeEventListener('pointermove', handleScroll);
            window.removeEventListener('touchmove', handleScroll);
            window.OneSignal = undefined;
        }
    }, []);

    const handleScroll = () => {
        if (document.querySelector('.overlay')) {
            document.body.style.overflow = 'hidden';
        } else if (document.body.style.overflow !== 'auto') {
            document.body.style.overflow = 'auto';
        }
    }
    return (
        <>
            <Head>
                <title>DesiWeds</title>
                <meta name="viewport" content="minimum-scale=1, initial-scale=1, maximum-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, user-scalable=0, viewport-fit=cover" />
            </Head>
            {
                loadingPage ?
                    <Splash />
                    :
                    <AuthProvider>
                        <AuthWrapper>
                            <PermissionWrapper>
                                <ChatProvider>
                                    <Provider store={store}>
                                        <Component {...pageProps} />
                                    </Provider>
                                </ChatProvider>
                            </PermissionWrapper>
                        </AuthWrapper>
                    </AuthProvider>
            }
        </>

    )
}

export default MyApp
