import dayjs from "dayjs";

export const conuntDown = (seconds, setSeconds, setTimer) => {
    if (!seconds > 0) return '00:00'
    const minutes = Math.floor(seconds / 60);
    const sec = seconds % 60;
    setSeconds(seconds - 1);
    return setTimer(`${timer(minutes)}:${timer(sec)}`);
}

const timer = ti => ti < 10 ? `0${ti}` : ti;

export const wait = (timeout) => {
    return new Promise(resolve => {
        setTimeout(resolve, timeout);
    });
}


export const _makeEmailObscure = (email) => {
    const [username, domain] = email.split("@");
    const tldm = domain.split(".")[0];
    const tld = domain.split(".")[1];
    // eslint-disable-next-line max-len
    return `${username.slice(0, 3)}${"*".repeat(Math.abs(username.length - 3))}@${tldm.substring(0, 1)}${"*".repeat(Math.abs((tldm.length) - 1))}.${tld}`;
}

function getHourMinute(time) {
    if (["AM", "PM"].includes(time.slice(-2))) {
        const timeSplit = time.split(':')
        let [hour, minutePart] = timeSplit
        let [minute, postFix] = minutePart.split(' ')
        if (postFix === 'PM') {
            hour = parseInt(hour) + 12
        }
        return [hour, minute]
    }
    return time.split(':')
}

export const setEventDate = (date, time) => {
    if (time && date) {
        let [hour, minute] = getHourMinute(time)

        return (dayjs(date)).hour(hour).minute(minute).$d
    }
    else if (!time && date) {
        return (dayjs(date)).$d
    }
    else {
        return ''
    }
}
export const setEventTime = (date, time) => {
    if (time && date) {
        let [hour, minute] = getHourMinute(time)

        return (dayjs(date)).hour(hour).minute(minute).$d
    }
    else if (!time && date) {
        return ''
    }
    else {
        return ''
    }
}
export const getEventDate = (eventDate) => {
    return eventDate?.toDate().toDateString()
}

export const getEventTime = (eventDate, type) => {
    let time;
    if (type === 'guest') {
        time = eventDate?.toTimeString().split(' ')[0]
    } else {
        time = eventDate?.toDate().toTimeString().split(' ')[0]
    }
    if (eventDate && time !== "00:00:00") {
        let value = time.substring(0, time.length - 3)
        return value
    }
    else {
        return ''
    }
}

// convert string to time format
export const totimeConvert = (time) => {
    if (!time) return
    // Check correct time format and split into components
    time = time
        .toString()
        .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];      

    if (time.length > 1) {
        // If time format correct
        time = time.slice(1); // Remove full string match value
        time[5] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(""); // return adjusted time or original string
}




